<!--
 * @Author: wy 15660501845@163.com
 * @Date: 2024-08-27 17:27:49
 * @LastEditors: WY 15660601845@163.com
 * @LastEditTime: 2024-09-09 14:48:00
 * @FilePath: /welfare-mall/welfare-mall-website/src/views/pages/golden-jubilee/article.vue
* @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="tw-article">
    <div class="tw-article-header">
      <img @click="router" class="tw-article-header__logo" src="https://xb-dmp-pro.oss-cn-shanghai.aliyuncs.com/upload/20240827/8b8e5f4efd9acbe360eeedc93aedd3b1.png" alt="widthFix" />
    </div>
    <div class="tw-article-center">
      <div class="tw-article-box">
        <div class="tw-box-top">您的当前位置：<a @click="router">首页</a> > {{ object.title }}</div>
        <div class="tw-box-center">
          <div class="tw-box-center__left">
            <div class="tw-box-center__left-box" @click="cutArticle(item)" :class="item.id === object.id ? 'tw-box-center__left-checked' : ''" v-for="(item, index) in list" :key="index"> ·{{ item.title }} </div>
          </div>
          <div class="tw-box-center__right">
            <template v-if="object.type === 'text' && object.html">
              <div v-html="object.html"></div>
            </template>
            <template v-if="object.type === 'img'">
              <el-image style="width: 100%; display: inline-block" :src="object.contentImg" :preview-src-list="[object.contentImg]"></el-image>
              <!-- <img style="width: 100%;display:inline-block" :src="object.contentImg" alt="widthFix"> -->
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  data() {
    return {
      object: {},
      list: []
    }
  },
  created() {
    axios
      .request({
        url:`${process.env.BASE_URL}/mock.json`,
        params: {
          time: new Date().getTime()
        },
        method: 'GET'
      })
      .then(res => {
        this.list = res.data.list
        this.object = res.data.list.find(item => item.id === Number(this.$route.query?.id))
      })
  },
  methods: {
    cutArticle(e) {
      if (this.object.id === e.id) {
        return
      }
      this.object = this.list.find(item => item.id === e.id) || {}
    },
    router() {
      this.$router.push('/index')
      window.scrollTo(0, 0)
    }
  }
}
</script>

<style lang="scss" scoped>
.tw-article {
  background: #f8f8f8;
  &-header {
    width: 100%;
    height: 80px;
    background: #ffffff;
    display: flex;
    align-items: center;
    &__logo {
      margin-left: 94px;
      width: 104px;
      display: inline-block;
      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
  &-center {
    padding: 80px 240px 106px;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    .tw-article-box {
      width: 100%;
      min-height: 1000px;
      background: #ffffff;
      .tw-box-top {
        padding-left: 120px;
        width: 100%;
        height: 80px;
        line-height: 80px;
        background: #fff;
        border-bottom: 1px solid #d9d9d9;
        box-sizing: border-box;
        font-weight: 400;
        font-size: 14px;
        color: #111111;
        font-style: normal;
        a:hover {
          cursor: pointer;
        }
      }
      .tw-box-center {
        width: 100%;
        display: flex;
        &__left {
          width: 33%;
          padding-top: 23px;
          box-sizing: border-box;
          &-box {
            padding-left: 120px;
            box-sizing: border-box;
            width: 100%;
            height: 65px;
            line-height: 65px;
            font-weight: 400;
            font-size: 16px;
            color: #292929;
            font-style: normal;
            &:hover {
              cursor: pointer;
            }
          }
          &-checked {
            background: #d9d9d9;
          }
        }
        &__right {
          flex: 1;
          padding: 41px 120px 50px 18px;
          box-sizing: border-box;
        }
      }
    }
  }
  @media screen and (max-width: 800px) {
    &-center {
      padding: 80px 0 106px;
    }
  }
}
</style>
